import React from 'react';
import strings from "../../../localization";
import { getError, hasError } from "../../../functions/Validation";
import { Button, TextField, FormLabel, FormControlLabel, Radio, FormControl, RadioGroup } from "@material-ui/core";
import DatePickerControl from '../../controls/DatePickerControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';
import { stringToDate } from '../../../util/DateUtil';
import moment from 'moment';

const CassetteRentForm = ({
  onSubmit,
  onCancel,
  onChange,
  errors,
  data,
  isDisabled,
  handleChange,
  onAutocompleteChange,
  handleTextInputChange,
  submitters,
  submitters2,
  submitter,
  submitter2,
  graveyards,
  graveSites,
  flag,
  date,
  flagAddNewSubmitter,
  renderAddCitizen,
  flagMarginValid,
  flagAddNewSubmitter2,
  flagMarginValid2,
  typedInGraveSite,
  keyPress,
  keyPressBackspace,
}) => {

  let renderAddCitizenButton = {
    marginLeft: "289px",
    marginTop: "-114px",
    paddingBottom: "-300px"
  }

  
  return (
    <form id='user-form'>

    
     <TextField
        label={strings.cassetteRent.form.mainBookOfBuried + "*"}
        error={hasError(errors, 'mainBookOfBuriedString')}
        helperText={getError(errors, 'mainBookOfBuriedString')}
        fullWidth
        autoFocus
        name='mainBookOfBuriedString'
        onChange={handleTextInputChange}
        margin="normal"
        defaultValue={date}
        value={data.mainBookOfBuriedString}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      
      {((data.submitter && flag == "edit") || (flag == "add") || (data.submitter && flag == "view")) &&
        <Autocomplete
          id={"submitter"}
          name={"submitter"}
          options={submitters}
          noOptionsText={""}
          getOptionLabel={option => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cassetteRent.form.personalNumber}
              margin="normal"
              style={{ width: 275 }}
              name={"submitter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={hasError(errors, 'submitter')}
              helperText={getError(errors, 'submitter')}
            />
          )}
          disabled={isDisabled}
          value={data.submitter}
          error={hasError(errors, 'submitter')}
          helperText={getError(errors, 'submitter')}
        />
      }
      {((!data.submitter && flag == "edit") || (!data.submitter && flag == "view")) &&
        <Autocomplete
          id={"submitter"}
          name={"submitter"}
          options={submitters}
          getOptionLabel={option => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cassetteRent.form.personalNumber}
              margin="normal"
              style={{ width: 275 }}
              name={"submitter"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={hasError(errors, 'submitter')}
              helperText={getError(errors, 'submitter')}
            />
          )}
          disabled={isDisabled}
          value={data.submitter}
          error={hasError(errors, 'submitter')}
          helperText={getError(errors, 'submitter')}
        />
      }

      {flagAddNewSubmitter == true && flagMarginValid == true &&
        <div className='submit-container'>
          <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
            {strings.reservation.form.addNewSubmitter}
          </Button>

        </div>
      }

      {flagAddNewSubmitter == true && flagMarginValid == false &&
        <div className='submit-container'>
          <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
            {strings.reservation.form.addNewSubmitter}
          </Button>
          <div class="renderAddCitizenButtonNotValidPersonalNumber">
            {strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID}
          </div>
        </div>
      }

      <TextField
        label={strings.cassetteRent.form.submitterName}
        error={hasError(errors, 'nameSurnameSubmitter')}
        helperText={getError(errors, 'nameSurnameSubmitter')}
        fullWidth
        name='nameSurnameSubmitter'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter.nameSurnameSubmitter}
        value={submitter.nameSurnameSubmitter}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.cassetteRent.form.submitterAddress}
        error={hasError(errors, 'addressSubmitter')}
        helperText={getError(errors, 'addressSubmitter')}
        fullWidth
        name='addressSubmitter'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter.addressSubmitter}
        value={submitter.addressSubmitter}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.cassetteRent.form.submitterMunicipality}
        error={hasError(errors, 'municipalitySubmitter')}
        helperText={getError(errors, 'municipalitySubmitter')}
        fullWidth
        name='municipalitySubmitter'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter.municipalitySubmitter}
        value={submitter.municipalitySubmitter}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.cassetteRent.form.submitterTelephone}
        error={hasError(errors, 'telephoneSubmitter')}
        helperText={getError(errors, 'telephoneSubmitter')}
        fullWidth
        name='telephoneSubmitter'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter.telephoneSubmitter}
        value={submitter.telephoneSubmitter}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      {((data.submitter2 && flag == "edit") || (flag == "add") || (data.submitter2 && flag == "view")) &&
        <Autocomplete
          id={"submitter2"}
          name={"submitter2"}
          options={submitters2}
          getOptionLabel={option => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cassetteRent.form.personalNumber2}
              margin="normal"
              style={{ width: 275 }}
              name={"submitter2"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.submitter2}
        />
      }
       
      {((!data.submitter2 && flag == "edit") || (!data.submitter2 && flag == "view")) &&
        <Autocomplete
          id={"submitter2"}
          name={"submitter2"}
          options={submitters2}
          getOptionLabel={option => option.personalNumber}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cassetteRent.form.personalNumber2}
              margin="normal"
              style={{ width: 275 }}
              name={"submitter2"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.submitter2}
        />
      }
      {flagAddNewSubmitter2 == true && flagMarginValid2 == true &&
        <div className='submit-container'>
          <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
            {strings.reservation.form.addNewSubmitter}
          </Button>

        </div>
      }

      {flagAddNewSubmitter2 == true && flagMarginValid2 == false &&
        <div className='submit-container'>
          <Button variant="contained" color="secondary" onClick={renderAddCitizen} style={renderAddCitizenButton}>
            {strings.reservation.form.addNewSubmitter}
          </Button>
          <div class="renderAddCitizenButtonNotValidPersonalNumber">
            {strings.citizen.messages.CITIZEN_PERSONAL_NUMBER_NOT_VALID}
          </div>
        </div>
      }
      <TextField
        label={strings.cassetteRent.form.submitterName2}
        error={hasError(errors, 'nameSurnameSubmitter2')}
        helperText={getError(errors, 'nameSurnameSubmitter2')}
        fullWidth
        name='nameSurnameSubmitter2'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter2.nameSurnameSubmitter2}
        value={submitter2.nameSurnameSubmitter2}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.cassetteRent.form.submitterAddress2}
        error={hasError(errors, 'addressSubmitter2')}
        helperText={getError(errors, 'addressSubmitter2')}
        fullWidth
        name='addressSubmitte2r'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter2.addressSubmitter2}
        value={submitter2.addressSubmitter2}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.cassetteRent.form.submitterMunicipality2}
        error={hasError(errors, 'municipalitySubmitter2')}
        helperText={getError(errors, 'municipalitySubmitter2')}
        fullWidth
        name='municipalitySubmitter2'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter2.municipalitySubmitter2}
        value={submitter2.municipalitySubmitter2}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      <TextField
        label={strings.cassetteRent.form.submitterTelephone2}
        error={hasError(errors, 'telephoneSubmitter2')}
        helperText={getError(errors, 'telephoneSubmitter2')}
        fullWidth
        name='telephoneSubmitter2'
        onChange={onChange}
        margin="normal"
        defaultValue={submitter2.telephoneSubmitter2}
        value={submitter2.telephoneSubmitter2}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={true}
      />
      {((data.graveyard && flag == "edit") || (flag == "add") || (data.graveyard && flag == "view")) &&
        <Autocomplete
          id={"graveyard"}
          name={"graveyard"}
          options={graveyards}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cassetteRent.form.graveyard}
              error={hasError(errors, 'graveyard')}
              helperText={getError(errors, 'graveyard')}
              margin="normal"
              fullWidth
              name={"graveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveyard}
        />
      }
      {((!data.graveyard && flag == "edit") || (!data.graveyard && flag == "view")) &&
        <Autocomplete
          id={"graveyard"}
          name={"graveyard"}
          options={graveyards}
          getOptionLabel={option => option.name}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cassetteRent.form.graveyard}
              error={hasError(errors, 'graveyard')}
              helperText={getError(errors, 'graveyard')}
              margin="normal"
              fullWidth
              name={"graveyard"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveyard}
        />

      }
      {((data.graveSite && flag == "edit") || (flag == "add") || (data.graveSite && flag == "view")) && ((data.graveyard != undefined && data.graveyard != null)) &&
        <Autocomplete
          noOptionsText={(typedInGraveSite != undefined ? strings.mainBookOfBuried.form.create + typedInGraveSite : "")}
          onKeyPress={keyPress}
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cassetteRent.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
          disableClearable={true}
          onKeyDown={keyPressBackspace}
        />
      }
      {((!data.graveSite && flag == "edit") || (!data.graveSite && flag == "view") && (data.graveyard != undefined && data.graveyard != null)) &&
        <Autocomplete
          noOptionsText={(typedInGraveSite != undefined ? strings.mainBookOfBuried.form.create + typedInGraveSite : "")}
          onKeyPress={keyPress}
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cassetteRent.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={isDisabled}
          value={data.graveSite}
          disableClearable={true}
          onKeyDown={keyPressBackspace}
        />
      }

      {(data.graveyard == undefined || data.graveyard == null) &&
        <Autocomplete
          id={"graveSite"}
          name={"graveSite"}
          options={graveSites}
          getOptionLabel={option => option.code}
          onChange={onAutocompleteChange}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={strings.cassetteRent.form.graveSite}
              error={hasError(errors, 'graveSite')}
              helperText={getError(errors, 'graveSite')}
              margin="normal"
              fullWidth
              name={"graveSite"}
              onChange={handleTextInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disabled={true}
          value={null}
          disableClearable={true}
        />
      }

      { flag == "view" && <TextField
        label={strings.cassetteRent.form.graveSiteSize}
        error={hasError(errors, 'gssize')}
        helperText={getError(errors, 'gssize')}
        fullWidth
        name='gssize'
        onChange={onChange}
        margin="normal"
        defaultValue={data.gssize}
        value={data.gssize}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />}
      <FormControl component="fieldset">
        <FormLabel className="checkboxLabel"> {strings.cassetteRent.form.deceasedInside} </FormLabel>
        <RadioGroup name="deceasedInside" value={(data.deceasedInside == true || data.deceasedInside == "true") ? "true" : "false"} onChange={handleChange} className="radioGroup">
          <FormControlLabel value={"true"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cassetteRent.form.yes}</span>} />
          <FormControlLabel value={"false"} disabled={isDisabled} control={<Radio />} label={<span className="formControlLabel">{strings.cassetteRent.form.no}</span>} />
        </RadioGroup>
      </FormControl>
      <TextField
        label={strings.cassetteRent.form.tombstoneType}
        error={hasError(errors, 'tombstoneType')}
        helperText={getError(errors, 'tombstoneType')}
        fullWidth
        name='tombstoneType'
        onChange={onChange}
        margin="normal"
        defaultValue={data.tombstoneType}
        value={data.tombstoneType}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.cassetteRent.form.contractNumber}
        error={hasError(errors, 'contractNumber')}
        helperText={getError(errors, 'contractNumber')}
        fullWidth
        name='contractNumber'
        onChange={onChange}
        margin="normal"
        defaultValue={data.contractNumber}
        value={data.contractNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      <TextField
        label={strings.cassetteRent.form.paymentNumber}
        error={hasError(errors, 'paymentNumber')}
        helperText={getError(errors, 'paymentNumber')}
        fullWidth
        name='paymentNumber'
        onChange={onChange}
        margin="normal"
        defaultValue={data.paymentNumber}
        value={data.paymentNumber}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />

      {(data.dateOfRentString != undefined && data.dateOfRentString != null && data.dateOfRentString != "") &&
        <TextField
          label={strings.cassetteRent.form.dateOfRentString}
          fullWidth
          name='dateOfRentString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.dateOfRentString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      <DatePickerControl
        label={strings.cassetteRent.form.dateOfRent + "*"}
        error={getError(errors, 'dateOfRent')}
        hasError={hasError(errors, 'dateOfRent')}
        helperText={getError(errors, 'dateOfRent')}
        placeholder={strings.cassetteRent.form.dateOfRent}
        name='dateOfRent'
        onChange={onChange}
        //date={data.dateOfRent ? new Date(data.dateOfRent) : data.dateOfRent}
        date={data.dateOfRent ? stringToDate(data.dateOfRentString) > moment(data.dateOfRent, 'YYYY-MM-DD') ? stringToDate(data.dateOfRentString).toDate() : new Date(data.dateOfRent) : data.dateOfRent}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />
      {(data.rentExpiryDateString != undefined && data.rentExpiryDateString != null && data.rentExpiryDateString != "") &&
        <TextField
          label={strings.cassetteRent.form.rentExpiryDateString}
          fullWidth
          name='rentExpiryDateString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.rentExpiryDateString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      <DatePickerControl
        label={strings.cassetteRent.form.rentExpiryDate}
        error={getError(errors, 'rentExpiryDate')}
        hasError={hasError(errors, 'rentExpiryDate')}
        helperText={getError(errors, 'rentExpiryDate')}
        placeholder={strings.cassetteRent.form.rentExpiryDate}
        name='rentExpiryDate'
        onChange={onChange}
        //date={data.rentExpiryDate ? new Date(data.rentExpiryDate) : data.rentExpiryDate}
        date={data.rentExpiryDate ? stringToDate(data.rentExpiryDateString) > moment(data.rentExpiryDate, 'YYYY-MM-DD') ? stringToDate(data.rentExpiryDateString).toDate() : new Date(data.rentExpiryDate) : data.rentExpiryDate}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />
      {(data.graveSiteExpiryDateString != undefined && data.graveSiteExpiryDateString != null && data.graveSiteExpiryDateString != "") &&
        <TextField
          label={strings.cassetteRent.form.graveSiteExpiryDateString}
          fullWidth
          name='graveSiteExpiryDateString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.graveSiteExpiryDateString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }
      <DatePickerControl
        label={strings.cassetteRent.form.graveSiteExpiryDate}
        error={getError(errors, 'graveSiteExpiryDate')}
        hasError={hasError(errors, 'graveSiteExpiryDate')}
        helperText={getError(errors, 'graveSiteExpiryDate')}
        placeholder={strings.cassetteRent.form.graveSiteExpiryDate}
        name='graveSiteExpiryDate'
        onChange={onChange}
        //date={data.graveSiteExpiryDate ? new Date(data.graveSiteExpiryDate) : data.graveSiteExpiryDate}
        date={data.graveSiteExpiryDate ? stringToDate(data.graveSiteExpiryDateString) > moment(data.graveSiteExpiryDate, 'YYYY-MM-DD') ? stringToDate(data.graveSiteExpiryDateString).toDate() : new Date(data.graveSiteExpiryDate) : data.graveSiteExpiryDate}
        isClearable={true}
        dateFormat='dd/MM/yyyy'
        disabled={isDisabled}
      />
       {(data.dateOfCancellationString != undefined && data.dateOfCancellationString != null && data.dateOfCancellationString != "") &&
        <TextField
          label={strings.cassetteRent.form.dateOfCancellation}
          fullWidth
          name='dateOfCancellationString'
          onChange={handleTextInputChange}
          margin="normal"
          value={data.dateOfCancellationString}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
        />
      }

      {(flag == "add" || flag == "edit" || flag == "view") &&
        <NumberFormat
          //thousandSeparator={true} className="" inputmode="decimal" displayType="decimal"
          customInput={TextField}
          type="text"
          label={strings.cassetteRent.form.rentAmount}
          error={hasError(errors, 'rentAmount')}
          helperText={getError(errors, 'rentAmount')}
          fullWidth
          name='rentAmount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.rentAmount}
          value={data.rentAmount}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          placeholder="0.00"
        />
      }
      {(flag == "add" || flag == "edit" || flag == "view") &&
        <NumberFormat
          //thousandSeparator={true} className="" inputmode="decimal" displayType="decimal"
          customInput={TextField}
          type="text"
          label={strings.cassetteRent.form.graveSiteAmount}
          error={hasError(errors, 'graveSiteAmount')}
          helperText={getError(errors, 'graveSiteAmount')}
          fullWidth
          name='graveSiteAmount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.graveSiteAmount}
          value={data.graveSiteAmount}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
          placeholder="0.00"
        />
      }

      {data.rentAmount == null && data.graveSiteAmount == null &&
        <TextField
          label={strings.cassetteRent.form.totalAmount}
          error={hasError(errors, 'totalAmount')}
          helperText={getError(errors, 'totalAmount')}
          fullWidth
          name='totalAmount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.totalAmount}
          value={0 + ".00"}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          placeholder="0.00"
        />
      }

      {data.rentAmount != null && data.rentAmount % 1 == 0 && data.graveSiteAmount == null &&
        <TextField
          label={strings.cassetteRent.form.totalAmount}
          error={hasError(errors, 'totalAmount')}
          helperText={getError(errors, 'totalAmount')}
          fullWidth
          name='totalAmount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.totalAmount}
          value={parseFloat(data.rentAmount) + ".00"}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          placeholder="0.00"
        />
      }

      {flag == "view" && data.rentAmount != null && data.rentAmount % 1 != 0 && data.graveSiteAmount == null &&
        <TextField
          label={strings.cassetteRent.form.totalAmount}
          error={hasError(errors, 'totalAmount')}
          helperText={getError(errors, 'totalAmount')}
          fullWidth
          name='totalAmount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.totalAmount}
          value={(data.rentAmount).toFixed(2)}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          placeholder="0.00"
        />
      }

      {(flag == "add" || flag == "edit") && data.rentAmount != null && data.rentAmount % 1 != 0 && data.graveSiteAmount == null &&
        <TextField
          label={strings.cassetteRent.form.totalAmount}
          error={hasError(errors, 'totalAmount')}
          helperText={getError(errors, 'totalAmount')}
          fullWidth
          name='totalAmount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.totalAmount}
          value={(data.rentAmount)}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          placeholder="0.00"
        />
      }

      {data.rentAmount == null && data.graveSiteAmount != null && data.graveSiteAmount % 1 == 0 &&
        <TextField
          label={strings.cassetteRent.form.totalAmount}
          error={hasError(errors, 'totalAmount')}
          helperText={getError(errors, 'totalAmount')}
          fullWidth
          name='totalAmount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.totalAmount}
          value={parseFloat(data.graveSiteAmount) + ".00"}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          placeholder="0.00"
        />
      }

      {flag == "view" && data.rentAmount == null && data.graveSiteAmount != null && data.graveSiteAmount % 1 != 0 &&
        <TextField
          label={strings.cassetteRent.form.totalAmount}
          error={hasError(errors, 'totalAmount')}
          helperText={getError(errors, 'totalAmount')}
          fullWidth
          name='totalAmount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.totalAmount}
          value={parseFloat(data.graveSiteAmount).toFixed(2)}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          placeholder="0.00"
        />
      }

      {(flag == "add" || flag == "edit") && data.rentAmount == null && data.graveSiteAmount != null && data.graveSiteAmount % 1 != 0 &&
        <TextField
          label={strings.cassetteRent.form.totalAmount}
          error={hasError(errors, 'totalAmount')}
          helperText={getError(errors, 'totalAmount')}
          fullWidth
          name='totalAmount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.totalAmount}
          value={data.graveSiteAmount}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          placeholder="0.00"
        />
      }

      {flag == "view" && data.rentAmount != null && data.graveSiteAmount != null &&
        <TextField
          label={strings.cassetteRent.form.totalAmount}
          error={hasError(errors, 'totalAmount')}
          helperText={getError(errors, 'totalAmount')}
          fullWidth
          name='totalAmount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.totalAmount}
          value={(+data.rentAmount + +data.graveSiteAmount).toFixed(2)}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          placeholder="0.00"
        />
      }

      {(flag == "add" || flag == "edit") && data.rentAmount != null && data.graveSiteAmount != null &&
        <TextField
          label={strings.cassetteRent.form.totalAmount}
          error={hasError(errors, 'totalAmount')}
          helperText={getError(errors, 'totalAmount')}
          fullWidth
          name='totalAmount'
          onChange={onChange}
          margin="normal"
          defaultValue={data.totalAmount}
          value={(+parseFloat(data.rentAmount) + +parseFloat(data.graveSiteAmount))}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={true}
          placeholder="0.00"
        />
      }

      <TextField
        multiline={true}
        rowsMax={5}
        label={strings.cassetteRent.form.note}
        error={hasError(errors, 'note')}
        helperText={getError(errors, 'note')}
        fullWidth
        name='note'
        onChange={onChange}
        margin="normal"
        defaultValue={data.note}
        value={data.note}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isDisabled}
      />
      {
        !isDisabled &&
        <div className='submit-container'>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {strings.cassetteRent.form.ok}
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {strings.cassetteRent.form.cancel}
          </Button>
        </div>
      }
    </form>
  )
};

export default CassetteRentForm;