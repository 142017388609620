import React, { Component } from 'react'
import { bindActionCreators } from "redux";
import * as Actions from "../../actions/Actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Paper } from "@material-ui/core";
import { getPostalCodesImproved } from '../../services/postalCodeImproved/PostalCodeImprovedService';
import strings from "../../localization";
import Validators from "../../constants/ValidatorTypes";
import FormComponent from "../../common/FormComponent";
import { withSnackbar } from "notistack";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import DeceasedMainBookOfBuriedForm from '../../components/forms/mainBookOfBuried/DeceasedMainBookOfBuriedForm';
import { getAllTransporters } from '../../services/transporter/TransporterService';
import { addMainBookOfBuried } from '../../services/mainBookOfBuried/MainBookOfBuriedService';
import { getSettlements } from '../../services/settlement/SettlementService';
import { getStates } from '../../services/state/StateService';
import { getOccupations } from '../../services/occupation/OccupationService';
import { getCauseOfDeaths } from '../../services/causeOfDeath/CauseOfDeathService';
import { getDoctors } from '../../services/doctor/DoctorService';
import { getFamousCitizens, getAllFamousCitizens } from '../../services/famousCitizen/FamousCitizenService';
import DeathReportMainBookOfBuriedForm from '../../components/forms/mainBookOfBuried/DeathReportMainBookOfBuriedForm';
import { getCitizenByPersonalNumber, getCitizens, getLeaserByGraveSiteId } from '../../services/citizen/CitizenService';
import FuneralMainBookOfBuriedForm from '../../components/forms/mainBookOfBuried/FuneralMainBookOfBuriedForm';
import { getGraveyards } from '../../services/graveyard/GraveyardService';
import { editGraveSite, getGraveNotTakenSites, getGraveSitesWithSelectedGraveyard } from '../../services/graveSite/GraveSiteService';
import { getStonecutters } from '../../services/stonecutter/StonecutterService';
import { getFirms, getFirmById } from '../../services/firm/FirmService';
import { getDateFromIdentificationNumber, validateDateFromRenewalPaymentSlipAndDate, validatePersonalNumber } from "../../functions/Validation";
import { addGraveSite } from '../../services/graveSite/GraveSiteService';
import { validateTypedInGraveSite } from "../../functions/Validation";
import { isNumeric } from '../../util/DataValidation';
import { getAutocompleteObjectsById, getScheduledFunerals } from '../../services/scheduledFuneral/ScheduledFuneralService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';


function TabContainer(props) {
  return (
    <Typography className="tabContainer" component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class AddMainBookOfBuried extends FormComponent {

  validationList = {
    deceasedNameSurname: [{ type: Validators.REQUIRED }],
    mainBookOfBuries: [{ type: Validators.REQUIRED }, { type: Validators.IS_NUMBER }],
    height: [{ type: Validators.IS_NEGATIVE_NUMBER }],
    weight: [{ type: Validators.IS_NEGATIVE_NUMBER }],
    yearOfBirth: [{ type: Validators.IS_VALID_YEAR }],
    expiryDate: [{ type: Validators.REQUIRED }],
    dateTimeFuneral: [{ type: Validators.REQUIRED }],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data ? props.data : {},
      errors: {},
      value: 0,
      submitter: {},
      cost: {},
      firmCost: {},
      userGraveSite: {},
      costSameAsSubmitter: false,
      userSameAsCost: false,
      userSameAsSubmitter: false,
      disabled: false,
      costInfo: {},
      userInfo: {},
      firmInfo: {},
      dateFlag: "invalidIDNumber",
      dateOfBirthWhenValidIDNumber: [],
    };

    this.state.sortBy = "name,ASC";

    this.props.changeFullScreen(false);

    this.submit = this.submit.bind(this);
    this.identificationSubmitterEqualsIdentificationCosts = this.identificationSubmitterEqualsIdentificationCosts.bind(this);
    this.identificationCostsEqualsIdentificationUser = this.identificationCostsEqualsIdentificationUser.bind(this);
    this.identificationSubmitterEqualsIdentificationUser = this.identificationSubmitterEqualsIdentificationUser.bind(this);

    this.keyPress = this.keyPress.bind(this);
  }

  componentDidMount() {
    getAllTransporters().then(response => {
      this.setState({
        transporters: response.data.entities,
      });
    });
    this.setState({
      data: {
        ...this.state.data,
        height: 0,
        weight: 0,
        mainBookOfBuries: new Date().getFullYear(),
      }
    });

    getAllFamousCitizens().then(response => {
      if (!response.ok) {
        return;
      }
      this.setState({
        famousCitizens: response.data.entities,
        data: {
          ...this.state.data,
          defaultFamousCitizen: (response.data.entities.find(x => x.id === 31) != undefined ? response.data.entities[30] : response.data.entities[0]),
        }
      });
    });

  }

  keyPress(event) {
    if (event.key == "Enter") {
      event.preventDefault();

      if (!validateTypedInGraveSite(this.state.typedInGraveSite)) {
        this.props.enqueueSnackbar(strings.graveSite.messages.GRAVE_SITE_INPUT_NOT_VALID, {
          variant: "error"
        });
        return;
      }

      addGraveSite(this.transformRequestGraveSite(), this.props.cid).then(response => {
        if (!response.ok) {

          let messageKey = response.response.data.message;

          this.props.enqueueSnackbar(strings.graveSite.messages[messageKey], {
            variant: "error"
          });

          this.handleError(messageKey);

          return;
        }

        this.setState({
          data: {
            ...this.state.data,
            graveSite: response.data,
            graveSiteCode: response.data.code,
            graveSiteId: response.data.id,
          }
        });

        this.props.enqueueSnackbar(strings.addCompany.graveSiteAdded, { variant: 'success' });

      });
    }
  }

  keyPressBackspace(e) {
    if (e.target.value.length == 2 && e.keyCode !== 8) {
      e.target.value = e.target.value + '/';
    }

    if (e.target.value.length == 5 && e.keyCode !== 8) {
      e.target.value = e.target.value + '-';
    }

    if (e.keyCode !== 8) {
      return;
    }

    if (e.target.value.charAt(e.target.value.length - 2) == "-" || e.target.value.charAt(e.target.value.length - 2) == "/") {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    }
    else {
      e.target.value = e.target.value.substring(0, e.target.value.length);
    }
  }

  validateCashRegisterNumber(paymentSlipAndDate) {
    let validCashRegisterNumberArray = ["03", "11", "28", "52", "70", "91", "92", "93"]
    if (!validCashRegisterNumberArray.includes(paymentSlipAndDate.substr(0, 2))) {
      return false;
    }
    return true;
  }

  keyPressBackspacePaymentSlip = (e) => {
    if (e.target.value.length == 2 && e.keyCode !== 8) {
      e.target.value = e.target.value + ' ';
    }

    if (e.target.value.length == 8 && e.keyCode !== 8) {
      e.target.value = e.target.value + '/';
    }

    if (e.target.value.length == 11 && e.keyCode !== 8) {
      e.target.value = e.target.value + '.';
    }

    if (e.target.value.length == 14 && e.keyCode !== 8) {
      e.target.value = e.target.value + '.';
    }

    if (e.keyCode !== 8) {
      return;
    }

  }

  onChangePaymentSlipAndDateField = (e) => {

    let validDate = validateDateFromRenewalPaymentSlipAndDate(e.target.value)
    let year = e.target.value.substr(15, 4)

    let validCashRegistryNumber = this.validateCashRegisterNumber(e.target.value)

    if (!validCashRegistryNumber) {
      this.setError("paymentNumber", "Blagajna ne valja!");
    }
    else if (!validDate || !isNumeric(year)) {
      this.setError("paymentNumber", "Datum ne valja!");
    }
    else {
      this.unsetError("paymentNumber")
    }

    if (e.target.value.length == 20) {
      return;
    }

    this.changeData(e);
  }

  transformRequestGraveSite() {
    return {
      userEmail: this.props.uemail,
      code: this.state.typedInGraveSite,
      belongingGraveyardId: this.state.data.graveyard.id,
      z: '0'
    }
  }

  transformRequestEditGraveSite(graveSite) {
    return {
      ...graveSite,
      userEmail: this.props.uemail,
      companyId: this.props.cid,
      belongingGraveyardId: this.state.data.graveyard.id,
      belongingGraveyardName: this.state.data.graveyard.name,
      expirationDate: this.state.data.expiryDate,
    }
  }

  onChangePersonalNumberField = (event) => {
    let validPersonalNumber = validatePersonalNumber(event.target.value)
    let dateOfBirth = "";
    let dateOfBirthArray = [];

    if (!validPersonalNumber) {
      this.setError("identificationNumber", strings.mainBookOfBuried.messages.MAIN_BOOK_OF_BURIED_IDENTIFICATION_NUMBER_NOT_VALID);
      this.setState({
        dateFlag: "invalidIDNumber"
      });
    }
    else {
      this.unsetError("identificationNumber");
      dateOfBirth = getDateFromIdentificationNumber(event.target.value);
      dateOfBirthArray = dateOfBirth.split('/');

      this.setState({
        dateOfBirthWhenValidIDNumber: dateOfBirthArray,
        dateFlag: "validIDNumber"
      });

    }
    this.changeData(event);
  }

  submit() {
    this.setState(
      {
        disabled: true
      }
    )
    if (!this.validate()) {
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
      this.setState(
        {
          disabled: false
        }
      )
      return;
    }

    if (this.state.data.mainBookOfBuries.length != 6) {
      this.setError("mainBookOfBuries", strings.mainBookOfBuried.errorLength);
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
      this.setState(
        {
          disabled: false
        }
      )
      return;
    }

    if (this.state.data.takeoverDateTime != null && this.state.data.dateTimeOfDeath != null && new Date(this.state.data.takeoverDateTime).getTime() <= new Date(this.state.data.dateTimeOfDeath).getTime()) {
      this.setError("dateTimeOfDeath", strings.error.error);
      this.setError("takeoverDateTime", strings.error.error);
      this.props.enqueueSnackbar(strings.error.dateOfDeathandTakeoverDate, { variant: 'error' });
      this.setState(
        {
          disabled: false
        }
      )
      return;
    }

    if (this.state.data.yearOfBirth == null) {
      this.setError("yearOfBirth", strings.mainBookOfBuried.errorLength);
      this.props.enqueueSnackbar(strings.error.requiredFields, { variant: 'error' });
      this.setState(
        {
          disabled: false
        }
      )
    }




    this.showDrawerLoader();

    if (this.state.data.expiryDate != null) {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          graveSite: {
            ...prevState.data.graveSite,
            expirationDate: this.state.data.expiryDate
          }
        }
      }), () => {
        editGraveSite(this.transformRequestEditGraveSite(this.state.data.graveSite)).then(() => {
          addMainBookOfBuried(this.transformRequest(this.state.data), this.props.cid).then(response => {
            if (!response.ok) {
              let messageKey = response.response.data.message;

              this.props.enqueueSnackbar(strings.mainBookOfBuried.messages[messageKey], {
                variant: "error"
              });

              this.handleError(messageKey);

              return;
            }

            this.setState({
              disabled: false
            })
            this.props.enqueueSnackbar(strings.addCompany.mainBookOfBuriedAdded, { variant: 'success' });
            this.props.onFinish(response.data.mainBookOfBuried);
            this.hideDrawerLoader();
          });
        });
      });
    }
  }

  transformRequest(data) {

    return {
      ...data,
      userEmail: this.props.uemail,
      transporterId: this.state.data.transporter ? this.state.data.transporter.id : null,
      causeOfDeathId: this.state.data.causeOfDeath ? this.state.data.causeOfDeath.id : null,
      submitterId: this.state.data.submitter ? this.state.data.submitter.id : null,
      costId: this.state.data.cost ? this.state.data.cost.id : null,
      userGraveSiteId: this.state.data.userGraveSite ? this.state.data.userGraveSite.id : null,
      firmCostId: this.state.data.firmCost ? this.state.data.firmCost.id : null,
      municipalityId: this.state.data.municipality ? this.state.data.municipality.id : null,
      stateId: this.state.data.state ? this.state.data.state.id : null,
      occupationId: this.state.data.occupation ? this.state.data.occupation.id : null,
      municipalityOfBirthId: this.state.data.municipalityOfBirth ? this.state.data.municipalityOfBirth.id : null,
      doctorId: this.state.data.doctor ? this.state.data.doctor.id : null,
      famousCitizenId: this.state.data.famousCitizen ? this.state.data.famousCitizen.id : this.state.data.defaultFamousCitizen.id,
      graveyardId: this.state.data.graveyard ? this.state.data.graveyard.id : null,
      graveSiteId: this.state.data.graveSite ? this.state.data.graveSite.id : null,
      stoneCutterId: this.state.data.stoneCutter ? this.state.data.stoneCutter.id : null,
      expiryDate: this.state.data.graveSite ? this.state.data.graveSite.expirationDate : this.state.data.expiryDate
    }
  }

  identificationSubmitterEqualsIdentificationCosts() {
    if (!this.state.costSameAsSubmitter) {
      getCitizenByPersonalNumber(this.state.submitter.personalNumber).then(response => {
        this.setState({
          costInfo: {
            nameSurnameCosts: response.data.nameSurname,
            addressCosts: response.data.address,
            municipalityCosts: response.data.municipality,
            telephoneCosts: response.data.telephone
          },
          data: {
            ...this.state.data,
            cost: response.data
          },
          costSameAsSubmitter: !this.state.costSameAsSubmitter
        });
      });
    } else {
      this.setState({
        cost: null,
        data: {
          ...this.state.data,
          cost: null,
        },
        costInfo: {
          nameSurnameCosts: "",
          addressCosts: "",
          municipalityCosts: "",
          telephoneCosts: ""
        },
        costSameAsSubmitter: !this.state.costSameAsSubmitter
      });
    }

  }

  identificationSubmitterEqualsIdentificationUser() {
    if (!this.state.userSameAsSubmitter) {
      getCitizenByPersonalNumber(this.state.submitter.personalNumber).then(response => {
        this.setState({
          userInfo: {
            nameSurnameUser: response.data.nameSurname,
            addressUser: response.data.address,
            municipalityUser: response.data.municipality,
            telephoneUser: response.data.telephone
          },
          data: {
            ...this.state.data,
            userGraveSite: response.data,
          },
          userSameAsSubmitter: !this.state.userSameAsSubmitter
        });
      });
    } else {
      this.setState({
        userGraveSite: null,
        userInfo: {
          nameSurnameUser: "",
          addressUser: "",
          municipalityUser: "",
          telephoneUser: "",
        },
        data: {
          ...this.state.data,
          userGraveSite: null,
        },
        userSameAsSubmitter: !this.state.userSameAsSubmitter
      });
    }
  }

  identificationCostsEqualsIdentificationUser() {
    if (!this.state.userSameAsCost) {
      getCitizenByPersonalNumber(this.state.data.cost.personalNumber).then(response => {
        this.setState({
          userInfo: {
            nameSurnameUser: response.data.nameSurname,
            addressUser: response.data.address,
            municipalityUser: response.data.municipality,
            telephoneUser: response.data.telephone,
          },
          data: {
            ...this.state.data,
            userGraveSite: response.data,
          },
          userSameAsCost: !this.state.userSameAsCost
        });
      });
    } else {
      this.setState({
        userGraveSite: null,
        userInfo: {
          nameSurnameUser: "",
          addressUser: "",
          municipalityUser: "",
          telephoneUser: "",
        },
        data: {
          ...this.state.data,
          userGraveSite: null,
        },
        userSameAsCost: !this.state.userSameAsCost
      });
    }
  }

  /* Handlers */

  handleError(message) {
    switch (message) {
      case "MAIN_BOOK_OF_BURIED_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST":
        this.setError("identificationNumber", strings.mainBookOfBuried.messages.MAIN_BOOK_OF_BURIED_WITH_IDENTIFICATION_NUMBER_ALREADY_EXIST);
        break;
      case "MAIN_BOOK_OF_BURIED_WITH_NAME_SURNAME_CANT_BE_NULL":
        this.setError("deceasedNameSurname", strings.mainBookOfBuried.messages.MAIN_BOOK_OF_BURIED_WITH_NAME_SURNAME_CANT_BE_NULL);
        break;
      case "MAIN_BOOK_OF_BURIED_EXIST":
        this.setError("mainBookOfBuries", strings.mainBookOfBuried.messages.MAIN_BOOK_OF_BURIED_EXIST);
        break;

    }
  }

  handleChangeTab = (event, value) => {
    this.setState({
      value: value
    });
  };

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data, [event.target.name]: event.target.value
      }
    })
  };



  /** 
   * Handles autocomplete change event
   * Triggers when user selects something from dropdown menu
   * to fill info (disabled) fields 
   */
  onAutocompleteChange = (event, values) => {
    let autocompleteName = event.target.id.split("-")[0];
    this.setState({
      data: {
        ...this.state.data, [autocompleteName]: values
      }
    });

    if (autocompleteName === "submitter" && values != null) {
      getCitizenByPersonalNumber(values.personalNumber).then(response => {
        this.setState({
          submitter: {
            personalNumber: response.data.personalNumber,
            nameSurnameSubmitter: response.data.nameSurname,
            addressSubmitter: response.data.address,
            municipalitySubmitter: response.data.municipality,
            telephoneSubmitter: response.data.telephone
          }
        });
      });
    } else if (autocompleteName === "cost" && values != null) {
      getCitizenByPersonalNumber(values.personalNumber).then(response => {
        this.setState({
          costInfo: {
            personalNumber: response.data.personalNumber,
            nameSurnameCosts: response.data.nameSurname,
            addressCosts: response.data.address,
            municipalityCosts: response.data.municipality,
            telephoneCosts: response.data.telephone
          }
        });
      });
    } else if (autocompleteName === "userGraveSite" && values != null) {
      getCitizenByPersonalNumber(values.personalNumber).then(response => {
        this.setState({
          userInfo: {
            personalNumber: response.data.personalNumber,
            nameSurnameUser: response.data.nameSurname,
            addressUser: response.data.address,
            municipalityUser: response.data.municipality,
            telephoneUser: response.data.telephone
          }
        });
      });
    } else if (autocompleteName === "firmCost" && values != null) {
      getFirmById(values.id, this.props.auth.user.id, this.props.auth.user.company.id).then(response => {
        this.setState({
          firmInfo: {
            taxNumberCost: response.data.taxNumber
          }
        });
      });
    } else if (autocompleteName === "graveSite" && values != null) {
      this.setState({
        data: {
          ...this.state.data,
          graveSite: values,
          expiryDateString: values.expirationDateString,
          expiryDate: values.expirationDate,
        },
      }, () => {
        getLeaserByGraveSiteId(values.id).then(response => {
          this.setState({
            data: {
              ...this.state.data,
              userGraveSiteId: response.data.id ? response.data.id : this.state.data.userGraveSite.id,
              userGraveSite: response.data.id ? response.data : this.state.data.userGraveSite,
            },
            userInfo: {
              ...this.state.userInfo,
              nameSurnameUser: response.data.name && response.data.surname ? response.data.name + " " + response.data.surname : null,
              addressUser: response.data.fullAddress ? response.data.fullAddress : null,
              municipalityUser: response.data.postalCodeImprovedZip ? response.data.postalCodeImprovedZip : null,
              telephoneUser: response.data.telephone ? response.data.telephone : null
            }
          });
        });
      });
    } else if (autocompleteName === "scheduledFuneral" && values != null) {
      getAutocompleteObjectsById(values).then(response => {
        if (!response.ok) {
          return;
        }
        const { dateTimeFuneral, appointmentTime } = values;

        const [hours, minutes] = appointmentTime.split(':').map(Number);

        const funeralDate = new Date(dateTimeFuneral);
        funeralDate.setDate(funeralDate.getDate() + 1); //account for the fact that the date is in UTC

        const scheduledFuneralTime = new Date(
          funeralDate.getUTCFullYear(),
          funeralDate.getUTCMonth(),
          funeralDate.getUTCDate(),
          hours,
          minutes
        );

        console.log(scheduledFuneralTime);

        let firmInfo = null;

        if (response.data.firmCost) {
          firmInfo = {
            taxNumberCost: response.data.firmCost.taxNumber,
            name: response.data.firmCost.name
          }
        }

        let data = {
          ...this.state.data,
          scheduledFuneral: values,
          identificationNumber: values.identificationNumber,
          deceasedNameSurname: values.deceasedNameSurname,
          gender: values.gender,
          fathersName: values.fathersName,
          address: values.lastAddressOfResidence,
          weight: values.weight,
          height: values.height,
          yearOfBirth: values.dateOfBirth ? values.dateOfBirth.substring(0, 4) : null,
          ...response.data,
          state: response.data.stateOfResidence,
          municipality: response.data.municipalityOfResidence,
          dateTimeFuneral: scheduledFuneralTime.toISOString(),
          dateTimeFuneralString: scheduledFuneralTime.toISOString(),
          expiryDate: response.data.graveSite?.expirationDate,
          expiryDateString: response.data.graveSite?.expirationDateString,
          firmPays: values.firmPays ? "true" : "false",
          firmInfo: firmInfo,
        };
        this.setState({
          occupations: [response.data.occupation],
          settlements: [response.data.municipalityOfBirth, response.data.municipalityOfResidence, response.data.municipalityOfDeath, response.data.municipalityOfFuneral],
          states: [response.data.stateOfResidence],
          submitter: {
            personalNumber: response.data.submitter?.personalNumber,
            nameSurnameSubmitter: values.submitterNameSurname,
            addressSubmitter: response.data.submitter?.fullAddress,
            municipalitySubmitter: response.data.submitter?.postalCodeImprovedZip,
            telephoneSubmitter: response.data.submitter?.telephone
          },
          costInfo: {
            personalNumber: response.data.cost?.personalNumber,
            nameSurnameCosts: values.costNameSurname,
            addressCosts: response.data.cost?.fullAddress,
            municipalityCosts: response.data.cost?.postalCodeImprovedZip,
            telephoneCosts: response.data.cost?.telephone
          },
          userInfo: {
            personalNumber: response.data.userGraveSite?.personalNumber,
            nameSurnameUser: values.userGraveSiteNameSurname,
            addressUser: response.data.userGraveSite?.street + " " + response.data.userGraveSite?.streetNumber,
            municipalityUser: response.data.userGraveSite?.postalCodeImprovedZip,
            telephoneUser: response.data.userGraveSite?.telephone
          },
          data: data,
          firmInfo: firmInfo,
        });
      });
    }
  }

  /** 
   * Handles autocomplete type input change event
   * When user typed in 2+ characters it searches for results to fill dropdown 
   */
  handleTextInputChange = (e) => {
    if (e.target.name === "municipality" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: 'city',
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getPostalCodesImproved(data).then(response => {

            if (!response.ok) {
              return;
            }
            this.setState({
              settlements: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "state" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getStates(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              states: response.data.entities,
            });
          });
      }
    }
    else if (e.target.name === "stateOfBirth" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getStates(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              states: response.data.entities,
            });
          });
      }
    }
    else if (e.target.name === "occupation" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getOccupations(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              occupations: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "causeOfDeath" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getCauseOfDeaths(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              causesOfDeath: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "doctor" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getDoctors(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              doctors: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "famousCitizen" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "description,ASC",
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getFamousCitizens(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              famousCitizens: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "submitter" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "surname,ASC",
        userId: 0
      };

      let validPersonalNumber = validatePersonalNumber(e.target.value);

      if (!validPersonalNumber) {
        this.setError("submitter", "");
        this.setState({
          flagMarginValid: false,
        });
      } else {
        this.unsetError("submitter");
        this.setState({
          flagMarginValid: true,
        });
      }

      this.setState({
        typedInPersonalNumber: e.target.value,
      });

      this.props.handler(e.target.value);

      {
        this.props.cid != undefined &&
          getCitizens(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              submitters: response.data.entities,
            }, () => {
              let flagDifferent = true;
              for (let i = 0; i < this.state.submitters.length; i++) {
                if (
                  this.state.typedInPersonalNumber ==
                  this.state.submitters[i].personalNumber
                ) {
                  flagDifferent = false;
                }
              }

              if (
                this.state.submitters.length == 0 ||
                flagDifferent == true
              ) {
                this.setState({
                  flagAddNewSubmitter: true,
                });
              } else {
                this.setState({
                  flagAddNewSubmitter: false,
                });
              }
            });
          });
      }
    } else if (e.target.name === "cost" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "surname,ASC",
        userId: 0
      };

      let validPersonalNumber = validatePersonalNumber(e.target.value);

      if (!validPersonalNumber) {
        this.setError("cost", "");
        this.setState({
          flagMarginValid2: false,
        });
      } else {
        this.unsetError("cost");
        this.setState({
          flagMarginValid2: true,
        });
      }

      this.setState({
        typedInPersonalNumber: e.target.value,
      });

      this.props.handler(e.target.value);

      {
        this.props.cid != undefined &&
          getCitizens(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              costs: response.data.entities,
            }, () => {
              let flagDifferent = true;
              for (let i = 0; i < this.state.costs.length; i++) {
                if (
                  this.state.typedInPersonalNumber ==
                  this.state.costs[i].personalNumber
                ) {
                  flagDifferent = false;
                }
              }

              if (
                this.state.costs.length == 0 ||
                flagDifferent == true
              ) {
                this.setState({
                  flagAddNewCost: true,
                });
              } else {
                this.setState({
                  flagAddNewCost: false,
                });
              }
            });
          });
      }
    } else if (e.target.name === "userGraveSite" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "surname,ASC",
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getCitizens(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              users: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "municipalityOfBirth" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: 'city',
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getPostalCodesImproved(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              settlements: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "graveyard" && e.target.value.trim().length >= 2) {

      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getGraveyards(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveyards: response.data.entities,
            });
          });
      }
      this.setState({
        graveSites: null,
        data: {
          ...this.state.data,
          graveSite: null
        }
      });
    } else if (e.target.name === "graveSite" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: "code,ASC",
        userId: 0,
        graveyardId: this.state.data.graveyard.id
      };

      this.setState({
        typedInGraveSite: e.target.value
      });

      {
        this.props.cid != undefined &&
          getGraveSitesWithSelectedGraveyard(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              graveSites: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "stoneCutter" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getStonecutters(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              stoneCutters: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "firmCost" && e.target.value.trim().length >= 2) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.cid,
        sort: this.state.sortBy,
        userId: 0
      };

      {
        this.props.cid != undefined &&
          getFirms(data).then(response => {
            if (!response.ok) {
              return;
            }
            this.setState({
              firms: response.data.entities,
            });
          });
      }
    } else if (e.target.name === "scheduledFuneral" && e.target.value.trim().length >= 4) {
      let data = {
        searchParam: e.target.value,
        companyId: this.props.auth.user.company.id,
        sort: 'dateCreated,DESC',
        userId: this.props.auth.user.id,
        page: 0,
        size: 10,
        filterDate: "",
      };

      getScheduledFunerals(data).then(response => {
        if (!response.ok) {
          return;
        }
        this.setState({
          scheduledFunerals: response.data.entities,
        });
      });
    }
  }

  render() {
    return (
      <Grid id='page' item md={12}>

        <div className='header'>
          <h1>{strings.mainBookOfBuried.pageTitle}</h1>
        </div>
        <div className="tabsDiv">
          <AppBar position="static">
            <Tabs value={this.state.value} className="tabs" onChange={this.handleChangeTab}>
              <Tab label={strings.mainBookOfBuried.deceased} className="fourTabs" />
              <Tab label={strings.mainBookOfBuried.deathReport} className="fourTabs" />
              <Tab label={strings.mainBookOfBuried.funeral} className="fourTabs" />
              <Tab label={strings.mainBookOfBuried.loadFromScheduledFuneral} className="fourTabs" />

            </Tabs>
          </AppBar>
          {this.state.value === 0 && <TabContainer>

            <DeceasedMainBookOfBuriedForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              transporters={this.state.transporters}
              municipalities={this.state.settlements == undefined ? [] : this.state.settlements}
              states={this.state.states == undefined ? [] : this.state.states}
              occupations={this.state.occupations == undefined ? [] : this.state.occupations}
              causesOfDeath={this.state.causesOfDeath == undefined ? [] : this.state.causesOfDeath}
              doctors={this.state.doctors == undefined ? [] : this.state.doctors}
              famousCitizens={this.state.famousCitizens == undefined ? [] : this.state.famousCitizens}
              flag={this.props.flag}
              dateFlag={this.state.dateFlag}
              dateOfBirthWhenValidIDNumber={this.state.dateOfBirthWhenValidIDNumber}
              onChangePersonalNumberField={this.onChangePersonalNumberField}
              date={(new Date().getFullYear())}
              defaultFamousCitizen={this.state.data.defaultFamousCitizen}
              disabled={this.state.disabled}
              scheduledFunerals={this.state.scheduledFunerals == undefined ? [] : this.state.scheduledFunerals}
            />

          </TabContainer>}
          {this.state.value === 1 && <TabContainer>

            <DeathReportMainBookOfBuriedForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              submitters={this.state.submitters == undefined ? [] : this.state.submitters}
              submitter={this.state.submitter}
              flagAddNewSubmitter={this.state.flagAddNewSubmitter}
              renderAddCitizen={this.props.flagAddCitizen}
              flagMarginValid={this.state.flagMarginValid}
              flagAddNewCost={this.state.flagAddNewCost}
              flagMarginValid2={this.state.flagMarginValid2}
              identificationSubmitterEqualsIdentificationCosts={this.identificationSubmitterEqualsIdentificationCosts}
              costs={this.state.costs == undefined ? [] : this.state.costs}
              cost={this.state.cost}
              identificationCostsEqualsIdentificationUser={this.identificationCostsEqualsIdentificationUser}
              users={this.state.users == undefined ? [] : this.state.users}
              userGraveSite={this.state.userGraveSite}
              identificationSubmitterEqualsIdentificationUser={this.identificationSubmitterEqualsIdentificationUser}
              costSameAsSubmitter={this.state.costSameAsSubmitter}
              userSameAsCost={this.state.userSameAsCost}
              userSameAsSubmitter={this.state.userSameAsSubmitter}
              costInfo={this.state.costInfo}
              userInfo={this.state.userInfo}
              firmInfo={this.state.firmInfo}
              flag={this.props.flag}
              firms={this.state.firms == undefined ? [] : this.state.firms}
              firmCost={this.state.firmCost}
              disabled={this.state.disabled}
            />

          </TabContainer>}
          {this.state.value === 2 && <TabContainer>

            <FuneralMainBookOfBuriedForm
              onChange={this.changeData}
              onSubmit={this.submit}
              data={this.state.data}
              errors={this.state.errors}
              onCancel={this.props.onCancel}
              handleChange={this.handleChange}
              onAutocompleteChange={this.onAutocompleteChange}
              handleTextInputChange={this.handleTextInputChange}
              graveyards={this.state.graveyards == undefined ? [] : this.state.graveyards}
              graveSites={this.state.graveSites == undefined ? [] : this.state.graveSites}
              stoneCutters={this.state.stoneCutters == undefined ? [] : this.state.stoneCutters}
              flag={this.props.flag}
              typedInGraveSite={this.state.typedInGraveSite}
              keyPress={this.keyPress}
              keyPressBackspace={this.keyPressBackspace}
              disabled={this.state.disabled}
              onKeyDown={this.keyPressBackspacePaymentSlip}
              onChangePaymentSlipAndDateField={this.onChangePaymentSlipAndDateField}
            />

          </TabContainer>}
          {this.state.value === 3 && <TabContainer>

            <form id='user-form'>
              <Autocomplete
                id={"scheduledFuneral"}
                name={"scheduledFuneral"}
                options={this.state.scheduledFunerals == undefined ? [] : this.state.scheduledFunerals}
                getOptionLabel={option => option.funeralNumber}
                onChange={this.onAutocompleteChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={strings.mainBookOfBuried.form.scheduledBurialNumber}
                    margin="normal"
                    fullWidth
                    name={"scheduledFuneral"}
                    onChange={this.handleTextInputChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                value={this.state.data.scheduledFuneral ?? null}
              />
            </form>


          </TabContainer>}
        </div>

      </Grid>

    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeFullScreen: Actions.changeFullScreen
  }, dispatch);
}

function mapStateToProps({ menuReducers, siteDataReducers }) {
  return { menu: menuReducers, siteData: siteDataReducers };
}

export default (withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddMainBookOfBuried))));